@import "style";

$sidebarWidth: 235 * $px;

.sidebar {
  position: absolute;
  top: 0;
  left: 0;
  width: 235 * $px;
  width: calc(14.6875em + env(safe-area-inset-left, 0px));
  padding-left: $safe-left;
  height: 100%;
  background: transparentize($dark, 0.05);
  z-index: 1000;
  color: white;
  opacity: 1;
  transform: translateX(0);
  transition: all 0.15s ease-out;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (orientation: portrait) {
    top: 45%;
    width: 100%;
    height: 55%;
    font-size: 2.4em;
    padding-bottom: 15*$px;
  }

  &.is-hidden {
    opacity: 0;
    transform: translateX(-20*$px);
    pointer-events: none;
  }

  &-arrow{
    position: absolute;
    top: 15*$px;
    right: 10*$px;
    font-size: 11.5*$px;
  }
}

.sidebar_title {
  width: 100%;
  text-align: center;
  padding: 10*$px;
  font-size: 14.5*$px;
  line-height: 25*$px;
  border-bottom: 1PX solid white;
}

.sidebar_preview {
  width: 100%;
  text-align: center;
  padding: 0*$px 10*$px 10*$px 10*$px;
  position: relative;

  &-content{
    background: #f1f1f1;
    border-radius: 10*$px;
    color: $dark;
    position: relative;
    min-height: 110*$px;

    .info_label {
      padding-right: 25*$px;
    }
  }

  &-close{
    position: absolute;
    top: 10*$px;
    right: 10*$px;
    font-size: 11.5*$px;
  }
}

.sidebar_content {
  flex: 1;
  padding: 8*$px;
  overflow: auto;
  width: 100%;
}

.sidebar_collection {
  flex: 1;
  width: 200*$px;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  align-content: flex-start;
}

.sidebar_item {
  margin: 5*$rpx;
  font-size: 40*$px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.15s transform ease-out;
  transform: scale(1);

  &.is-highlighted {
    z-index: 10;
  }
}

.sidebar_controls {
  width: 100%;
  text-align: center;
  padding: 10*$px 10*$px 10*$px 10*$px;
  position: relative;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1PX solid white;
}

.sidebar_icon {
  width: 25*$px;
  height: 25*$px;
  font-size: 20*$px;
  opacity: 0.15;
  transition: opacity 0.25s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;

  &.is-active {
    opacity: 1.0;
  }
}
