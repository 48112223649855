@import "style";

.strategy {
    position: relative;
    background: $dark;
    color: $light;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5*$px 20*$px 5*$px 25*$px;

    & + & {
      margin-top: 5*$px;
    }

    &-up {
      position: absolute;
      left: 8*$px;
      opacity: 0.5;
      font-size: 0.8em;
    }
}

.strategy_items {
    display: flex;
    align-items: center;
    height: 25*$px;

    &-title {
      font-size: 10*$px;
      margin-right: 5*$px;
    }
    &-row {
      display: flex;
      align-items: center;
      height: 100%;
      font-size: 25*$px;
    }
}

.strategy_slot {
    height: 100%;
    position: relative;
}