@import "style";

.flavour {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  z-index: 2;
  background: #020a1a9c;
  padding-right: 5*$px;

  &-cast {
    position: relative;
    display: flex;
    font-size: 50*$px;
    display: flex;
  }
}

.flavour_sequence {
  font-size: 40*$px;
  display: flex;
  padding: 0.12em;

  @media only screen and (orientation: portrait) {
    height: 2em;
    font-size: 2em;
    border-left: none;
  }

  >*:not(:first-child){
    margin-left: 0.1em;
  }
}
