@import "style";

.leveldot {
    font-size: 4em;
    position: relative;
    display: block;
    width: 1em;
    height: 1em;
    margin-top: -0.2em;
    transform: translate(-50%, -50%);

    &-number {
        font-size: 0.7em;
        font-weight: 900;
        position: absolute;
        top: -0.15em;
        left: 0;
        width: 100%;
        text-align: center;
        background: -webkit-linear-gradient(#ffc421, #f9ed3d);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 0.05em;
        -webkit-text-stroke-color: $dark;

        .is-locked & {
            background: none;
        }
    }

    &-stars {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

.leveldot_stars {
    font-size: 0.4em;
    display: flex;
    align-items: center;

    &-star {
        width: 10*$px;
        height: auto;
        margin-right: 0.07em;

        &:first-child, &:last-child {
            margin-top: -5*$px;
        }
    }
}