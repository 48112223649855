@import 'style';

.about {
    color: white;
    min-height: 100vh;
    font-size: 0.84em;

    &-close {
        position: absolute;
        z-index: 50;
        top: 0.2em;
        right: 0.2em;
        width: 1em;
        height: 1em;
        font-size: 2.5em;
        border: 1px solid $dark;
        border-radius: 50%;
        color: $dark;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;

        .icon {
            font-size: 0.5em;
        }
    }

    &-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
    }

    &-menu {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 30*$px;
        height: 100%;

        >* {
            margin-top: 15*$px;
        }
    }

    &-show {
        margin-top: 20*$px;
        font-size: 11*$px;
    }

    ul {
      padding: 0 0 0 22*$px;
      margin: 0;
    }

    p {
      padding-bottom: 6*$px;
    }

    a {
        text-decoration: underline;
    }
}

.about_ads {

    &-status {
        display: relative;
    }
}

.about_consent {
    padding: 10*$px 0;
    font-size: 14*$px;

    a {
        text-decoration: underline;
    }

    &-checkbox {
        display: flex;

        input {
            margin-right: 10*$px;
        }
    }
}

.about_codepopup {
    padding: 20*$px;

    &-input {
        width: 100%;
        padding: 15*$px;
        border: 1px solid gray;
    }

    input[type=button], input[type=submit], input[type=reset] {
        background-color: #4CAF50;
        border: none;
        color: white;
        padding: 16px 32px;
        text-decoration: none;
        margin: 4px 2px;
        cursor: pointer;
    }
}

.about_reportpopup {
    padding: 20*$px;

    &-input {
        width: 100%;
        padding: 15*$px;
        border: 1px solid gray;
    }

    input[type=button], input[type=submit], input[type=reset] {
        background-color: #4CAF50;
        border: none;
        color: white;
        padding: 16px 32px;
        text-decoration: none;
        margin: 4px 2px;
        cursor: pointer;
    }
}