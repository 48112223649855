@import "style";

.score {
    align-items: center;
    justify-content: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 30*$px;
}

.score_result {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 15*$px;

    &-result {
        width: 100%;
        font-size: 20*$px;
        display: flex;
        justify-content: space-between;
        padding: 20*$px 90*$px;
    }

    &-stats {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 15*$px 20*$px 15*$px;
    }

    &-stat {
        min-width: 50*$px;
        display: flex;
        flex-direction: column;
        font-size: 16*$px;
        justify-content: center;
        align-items: center;

        &-label {
            font-size: 0.75em;
        }

        &-amount {
            font-weight: bold;
        }

        &-score {
            font-size: 0.9em;
            &::after {
                content: ' score';
                font-size: 0.7em;
            }
        }
    }
}