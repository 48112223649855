@import "style";

.equiptab {

  &-items {
    padding: 0 10*$px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .equiptab-items-stub, .equipslot {
      width: 100*$px;
      height: 80*$px;
      margin: 3*$px;
    }
  }
}
