@import "style";

.levelselector {
  display: flex;
  justify-content: center;
  font-size: 0.8em;
  margin-bottom: 5*$px;

  &-level {
    font-weight: bold;
    display: block;
    padding: 5*$px;
    border: 2*$px solid white;

    &.is-active{
      color: $dark;
      background: white;
      border: 2*$px solid white;
    }
    &:not(:first-child){
      margin-left: 10*$px;
    }
  }
}
