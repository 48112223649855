@import "style";

$secondaryColor: #27dc95;

.achievement {
    background: $dark;
    color: white;
    padding: 5*$px;
    border-radius: 5*$px;
    display: flex;
    align-items: center;
    
    >* {
        opacity: 0.65;
    }
    &.is-active {
        >* {
            opacity: 1.0;
        }
    }

    &-icon {
        width: 1.5em;
        height: 1.5em;
        border: 2*$px solid white;
        border-radius: 4*$px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        flex-grow: 0;
    }

    &-info {
        flex: 1;
    }
}

.achievement_info {
    padding: 0 5*$px;

    &-top {
        font-size: 0.8em;
        margin-bottom: 4*$px;
    }
    &-bot{
        font-size: 0.7em;
        color: $secondaryColor;
        font-weight: bold;
    }
}

.achievement_rewards {
    font-size: 0.8em;
    display: inline-flex;
    align-items: flex-start;
    padding: 0 0.2em;
    color: white;

    >div:not(:last-child) {
        margin-right: 1em;
    }
}

.achievement_levels {
    font-size: 0.7em;
    padding-bottom: 0.8em;
    display: flex;

    &-item {
        height: 1em;
        width: 1.2em;
        position: relative;

        &-circle {
            position: relative;
            overflow: hidden;
            width: 1em;
            height: 1em;
            border-radius: 50%;
            border: 2*$px solid $secondaryColor;

            &-fill {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                background: $secondaryColor;
            }
        }

        &-status {
            position: absolute;
            right: 11*$px;
            border-right: 2*$px solid $secondaryColor;
            top: 100%;
            padding-top: 5*$px;
            padding-right: 4*$px;
            line-height: 1em;
        }
    }
}