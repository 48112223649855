@import "style";

.stage {
    backface-visibility: hidden; //required to avoid bad performance during css transitions
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    &-levels {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        font-size: 1.5em;
        width: 200*$px;
    }
}