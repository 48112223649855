@import "style";

.itemstats {
  display: flex;
  flex: 1;

  &-left {
    width: 50%;
    padding-right: 5*$px;
    text-align: left;
  }

  &-right {
    width: 50%;
    padding-left: 5*$px;
    text-align: right;
  }
}

.itemstats_stat {
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  line-height: 16* $px;
  align-items: center;
  margin-bottom: 2 * $px;

  &-icon{
    font-size: 1.2em;
    margin-left: 5* $px;
  }
}

