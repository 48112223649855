@import "style";

.draggable {
  transition: 0.15s ease-out;
  transform-origin: center center;
  transform: scale(1.0);

  &.is-dragging {
    opacity: 0.15;
  }
}

.draggable_clone {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  opacity: 0.8;
  pointer-events: none;
}
