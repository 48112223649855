@import "style";

.staticon {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  text-align: center;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background: linear-gradient(
    to top,
    rgba(256, 256, 256, 0.82),
    rgba(256, 256, 256, 0.82)
  ) currentColor;

  &-icon{
    font-size: 0.75em;
    height: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.mod-xp {
    color: $xp;
  }

  &.mod-currency {
    color: $currency;
  }

  &.mod-unit {
    margin-left: 3*$px;
  }

  &.mod-health {
    color: $health;
  }

  &.mod-energy {
    color: $energy;
  }

  &.mod-strength {
    color: $strength;
  }

  &.mod-agility {
    .staticon-icon {
      font-size: 0.55em;
    }
    color: $agility;
  }

  &.mod-armor {
    color: $armor;
  }

  &.mod-shielding {
    color: $shielding;
  }

  &.mod-intelligence {
    .staticon-icon {
      font-size: 0.65em;
    }
    color: $intelligence;
  }

  &.mod-resistance {
    color: $intelligence;
  }

  &.mod-time {
    color: $time;
  }

  &.mod-melee {
    color: $strength;
  }

  &.mod-ranged {
    color: $agility;
  }
  
  &.mod-magic {
    color: $intelligence;
  }
}
