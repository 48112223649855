@import "style";

* {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;

  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

html{
  color: $dark;

  @media (max-aspect-ratio: #{"667/375"}) {
    font-size: 100vw * (16/667);
  }

  @media (min-aspect-ratio: #{"667/375"}) {
    font-size: 100vh * (16/375);
  }
}

//LINK
a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;

  //IMG
  img {
    border: none;
  }
}

//BUTTON-INPUT-SELECT-TEXTAREA
button,
input,
select,
textarea {
  font: inherit;
  border: none;
  border-radius: 0;
  background: none;

  &:focus {
    outline: 0;
  }
}
