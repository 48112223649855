@import "style";

.icon {
  height: 1em;
  width: auto;

  svg {
    fill: currentColor;
  }

  &.mod-reverse {
    transform: scale(-1, 1);    
  }
}
