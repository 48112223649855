@import "style";

$px: 0.03em * 0.7;

.hpbar  {
  position: relative;

  &.is-reversed {
    transform: scale(-1, 1);
  }

  &.mod-large {
    width: 260 * $px;
    height: 42 * $px;

    .hpbar-value {
      position: absolute;
      top: 10 * $px;
      left: 48 * $px;
      width: 197 * $px;
      height: 20 * $px;
    }
  }

  &.mod-medium {
    width: 223 * $px;
    height: 33 * $px;

    .hpbar-value {
      position: absolute;
      top: 10 * $px;
      left: 48 * $px;
      width: 157 * $px;
      height: 13 * $px;
    }
    .hpbar-value-amount{
      font-size: 0.25em;
    }
  }

  &-value {
    display: flex;
    justify-content: center;
    align-items: center;

    &-amount{
      z-index: 1;
      font-size: 0.3em;
      color: rgba(1, 22, 39, 0.75);

      .is-reversed & {
        transform: scale(-1, 1);
      }
    }
  }
}

.hpbar_bubble  {
  position: absolute;
  left: 90%;
  background: white;
  border-radius: 50%;
  font-size: 1.4em;
  margin-top: -0.2em;
  height: 1em;
  width: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2.5*$px solid currentColor;
  z-index: 100;

  &-content {
    font-size: 0.42em;
    display: flex;
    font-weight: bold;
    color: $dark;

    .is-reversed & {
      transform: scale(-1, 1);
    }

    >*:not(:first-child) {
      margin-left: 5*$px;
    }
  }
}

.hpbar_shape {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;

  svg {
    fill: currentColor;
  }
  div {
    background: currentColor;
  }

  &.mod-color-white {
    svg {
      fill: white;
    }
    div {
      background: white;
      border-bottom: 1*$px solid #eeeff1;
    }
  }
  &.mod-color-gray {
    svg {
      fill: #eeeff1;
    }
    div {
      background: #eeeff1;
    }
  }
  &.mod-color-yellow {
    svg {
      fill: #fcf57c;
    }
    div {
      background: #fcf57c;
    }
  }

  &-bar {
    position: relative;
    background: white;
    height: 100%;
    flex: 1;
    margin-left: -1px;
    margin-right: -1px;
  }
}

.hpbar_left {
  flex-shrink: 0;
  height: 100%;
  width: auto;
}
.hpbar_right {
  flex-shrink: 0;
  height: 100%;
  width: auto;
}
