@import "style";

.info {
  display: flex;
  flex-direction: column;
  padding: 10*$px;
  text-align: left;
}

.info_label {
  font-size: 13*$px;
  margin-bottom: 12*$px;
}

.info_visual {
  height: 1em;
  font-size: 35*$px;
}

.info_cols {
  display: flex;
  align-items: flex-start;

  >*:not(:first-child) {
    margin-left: 10*$px;
  }
}

.info_description {
  font-size: 0.72em;
  width: 100%;
}
