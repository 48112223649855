@import "style";


.library {
    position: fixed;
    z-index: 9950;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: white;

    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;

    &-categories {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid $dark;

        &-item {
            padding: 0.5em 2em;
            color: $dark;

            &.is-active {
                background: lightgray;
            }
        }
    }

    &-close {
        position: absolute;
        top: 0.2em;
        right: 0.2em;
        width: 1em;
        height: 1em;
        font-size: 2.5em;
        border: 1px solid $dark;
        border-radius: 50%;
        color: $dark;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;

        .icon {
            font-size: 0.5em;
        }
    }

    &-content {
        display: flex;
        flex: 1;
        overflow: scroll;
        padding: 5*$px 10*$px 0 10*$px;
    }
}

.library_list {
    overflow-x: hidden;
}

.library_link {
    display: block;
    color: $dark;
    padding: 0.5em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.is-active {
        background: lightgray;
    }

    >svg, >.visual {
        margin-right: 0.25em;
    }
}

.library_item {
    color: $dark;
    padding: 0.5em;
    font-size: 1.2em;

    &-header {
        display: flex;
        font-size: 45*$px;
        align-items: center;

        &-visual {
            margin-right: 0.2em;
            width: 1em;
            height: 1em;
        }

        &-label {
            font-size: 0.4em;
        }
    }

    &-price, &-stats, &-bonus {
        margin-top: 0.5em;
        width: 200*$px;

        >div {
            margin-top: 0.5em;
        }
    }
}

.library_creature {
    font-size: 1.05em;
}