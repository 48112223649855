@import "style";

.finished {
    width: 100%;
    min-height: 140*$px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-result {
        display: flex;
        margin-bottom: 10*$px;
        font-size: 1.2em;

        &-levels {
            margin-left: 5*$px;
        }
    }
}