@import "style";

.weaponstats {

}

.weaponstats_stat {
  display: flex;

  .staticon {
    margin-right: 3*$px;
  }
}
