@import "style";

.visual{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  //vertical-align: middle;
  width: 1em;
  height: 1em;
  position: relative;
  background: $dark;
  border-radius: 2*$px;
  box-shadow: 0.5*$px 0.5*$px 3*$px black;
  color: white;

  &.mod-highlight {
    background: #044980;
  }

  &.mod-transparent {
    background: transparent;
    color: inherit;
    box-shadow: none;
  }

  &.mod-bw {
    img {
      filter: grayscale(100%);
    }
  }

  >* {
    overflow: hidden;
  }

  &-selected {
    position: absolute;
    top: -5%;
    left: -5%;
    width: 110%;
    height: 110%;
    border-radius: 5*$px;
    border: 0.1em solid #6bd043;
    transition: all 0.15s ease-out;
  }
}

.visual_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2*$px;
  color: white;

  svg {
    fill: currentColor;
  }

  &.mod-bottomright {
    justify-content: flex-end;
    align-items: flex-end;
  }
}

.visual_up {
  font-size: 6*$px;
  position: absolute;
  top: -3*$px;
  right: -3*$px;
  color: white;
}

.visual_icon {
  font-size: 0.3em;
}

.visual_image {
  width: 102%;
  height: 102%;
  overflow: hidden;
}

.visual_subscript {
  font-size: 0.2em;
  background: $dark;
  padding: 0.1em;
  border: 1PX solid white;
  border-radius: 2PX;
  margin: 0.25em;
}@import "style";

.visual{
  display: inline-block;
  //vertical-align: middle;
  width: 1em;
  height: 1em;
  position: relative;
  background: $dark;
  border-radius: 2*$px;
  box-shadow: 0.5*$px 0.5*$px 3*$px black;
  color: white;

  &.mod-transparent {
    background: transparent;
    color: inherit;
    box-shadow: none;
  }

  &.mod-bw {
    img {
      filter: grayscale(100%);
    }
  }

  >* {
    overflow: hidden;
  }

  &-selected {
    position: absolute;
    top: -5%;
    left: -5%;
    width: 110%;
    height: 110%;
    border-radius: 5*$px;
    border: 0.1em solid #6bd043;
    transition: all 0.15s ease-out;
  }
}

.visual_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2*$px;

  &.mod-bottomright {
    justify-content: flex-end;
    align-items: flex-end;
  }
}

.visual_up {
  font-size: 6*$px;
  position: absolute;
  top: -3*$px;
  right: -3*$px;
  color: white;
}

.visual_icon {
  font-size: 0.3em;
}

.visual_image {
  width: 102%;
  height: 102%;
  overflow: hidden;
}

.visual_subscript {
  font-size: 0.2em;
  background: $dark;
  padding: 0.1em;
  border: 1PX solid white;
  border-radius: 2PX;
  margin: 0.25em;
}
