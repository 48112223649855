@import "style";

.ranking {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 10*$px;
    justify-content: space-between;
}

.ranking_column {
    width: 170*$px;
    background: $dark;
    color: white;
    height: 100%;
    display: flex;
    flex-direction: column;    
    justify-content: center;
    align-items: center;
    padding: 5*$px;

    &-label {
        padding: 10*$px;
        font-size: 22*$px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 10*$px;
    }
}

.ranking_endless {
    display: flex;

    &-result {
        margin-left: 5*$px;
    }
}

.ranking_chapter {
    display: flex;
    flex-direction: column;
}

.ranking_result {
    display: flex;
    align-items: center;
    margin-top: 5*$px;

    .icon {
        margin-right: 8*$px;
    }
}