@import "style";

.logs{
  &-messages{
    font-size: 12 * $px;
    display: flex;
    flex-direction: column;
    padding-top: 15 * $px;
    padding-bottom: 15 * $px;
  }
}
