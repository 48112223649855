@import "style";

.log {
    width: 100%;

    &:not(:first-child){
      margin-top: 13 * $px;
    }
}

.log_msg {
    display: flex;
    align-items: center;

    &:last-child {
        .log_msg-pre-arrow {
            display: none;
        }
    }

    &-pre {
      flex-shrink: 0;
      width: 1.2em;
      display: flex;
      justify-content: center;
      align-items: center;

      &-arrow{
        width: 0;
        height: 0;
        border-top: 0.3em solid transparent;
        border-bottom: 0.3em solid transparent;
        border-left: 0.3em solid white;
        transform: rotate(0);
        transition: transform 0.2s ease-out;

        &.is-open {
          transform: rotate(90deg);
        }
      }
    }

    &-description{
      flex: 1;
    }
}

.log_children {
    font-size: 0.9em;
    padding-left: 25*$px;
    flex-direction: column;
    padding-top: 8*$px;
    display: none;

    &.is-open {
        display: flex;
    }
}

.log_mod {
  display: flex;
  padding-top: 0.2em;

  &-char {
    padding-right: 5*$px;
  }
  &-effect {
    &:before { 
      content: '|';
      padding-right: 5*$px;
    }
  }
  &-result {
    flex: 1;
    text-align: right;
  }
}