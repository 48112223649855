@import "style";

.popuptab{
  padding: 15px;
  width: 100%;
}

.popuptab_title{
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
}

.popuptab_subtitle{
  .popuptab_title + & {
    margin-top: -10px;
  }

  text-align: center;
  font-size: 11px;
  margin-bottom: 20px;
}

.popuptab_items{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  align-content: flex-start;

  >* {
    font-size: 2em;
    margin: 4*$px;
  }
}
