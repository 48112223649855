@import "style";

.lineup {
  display: flex;
  width: 400*$px;
  height: 250*$px;
  border-radius: 15*$px;
}

.lineup_line {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 0;
  &:first-child, &:last-child {
    z-index: 1;
  }
}

.lineup_spacer {
  flex: 1;
  font-size: 1.5em;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lineup_circle {
  opacity: 0.5;
  position: relative;

  &-slot {
    width: 1em;
    height: 1em;
    font-size: 70*$px;
    margin: 0.12em 0;

    .mod-rewards & {
      opacity: 0.25;
    }
  }

  &-rewards {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &.is-active {
    opacity: 1.0;
  }

  &.mod-mirrored {
    transform: scale(-1,1);
  }

  &.mod-grayscale {
    opacity: 0.5;
    img {
      filter: grayscale(1);
    }
  }
}

.lineup_rewards {
  color: white;
  width: 100%;

  &-levels {
    padding: 5*$px;
    border-radius: 5*$px;
    width: fit-content;
    font-weight: bold;
    color: $dark;
    white-space: nowrap;
  }

  &-achievements {
    color: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14*$px;
    flex-wrap: wrap;

    &-item {
      display: flex;
      margin: 0.05em;
      width: 1.5em;
      height: 1.5em;
      background: $dark;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
    }
  }
}