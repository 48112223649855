@import "style";

.droppable {
  position: relative;

  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    opacity: 0;
    pointer-events: none;

    &.is-hovering {
      opacity: 0.5;
    }
  }
}
