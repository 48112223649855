@import "style";

.badge {
    background: red;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &-amount {
        color: white;
        font-weight: bold;
        font-size: 0.6em;
    }
}

.badge_floating {
    position: absolute;
    top: -2*$px;
    right: -2*$px;
}