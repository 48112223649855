@import "style";

.itemspopup-products {
    padding: 10*$px;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: scroll;

    @media only screen and (orientation: portrait) {
        flex-wrap: wrap;
        justify-content: center;
    }

    >* {
        margin-right: 10*$px;

        @media only screen and (orientation: portrait) {
            margin: 5*$px;
        }
    }
}
