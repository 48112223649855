@import "style";

.table_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.7em;
  padding: 5*$px;

  &:nth-of-type(odd) {
    background: rgba(255, 255, 255, 0.16);
  }
}

.info_cols + .table_row {
  margin-top: 10*$px;
}

.table_key {
  width: 40%;
  font-size: 0.85em;
}

.table_value {
  width: 60%;

  .staticon {
    margin-left: 3*$px;
  }
}

