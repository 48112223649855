@import "style";

.conditionsetup {
  width: 100%;
  display: flex;
  flex-direction: column;

  >* {
    margin-top: 10*$px;
  }
}

.conditionsetup_select {
  width: 100%;
  max-width: 250*$px;

  &-input {
    width: 100%;
    padding: 5*$px;
    background: white;
    color: $dark;
  }
}

.conditionsetup_percent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-input {
    -webkit-appearance: none;
    width: 80%;
    max-width: 200*$px;
    height: 15*$px;
    border-radius: 5*$px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 25*$px;
      height: 25*$px;
      border-radius: 50%;
      background: #4CAF50;
      cursor: pointer;
    }
    &::-moz-range-thumb {
      width: 25*$px;
      height: 25*$px;
      border-radius: 50%;
      background: #4CAF50;
      cursor: pointer;
    }
  }

  &-value{
    margin-left: 10*$px;
    display: flex;
    
    .icon {
      margin-left: 3*$px;
    }
  }
}
