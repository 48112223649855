@import "style";

.popup{
  position: relative;
  width: 550*$px;
  height: 340*$px;
  background: white;
  padding: 3*$px;
  border-radius: 7*$px;
  display: flex;
  flex-direction: column;
  box-shadow: 1*$px 1*$px 10*$px black;

  @media only screen and (orientation: portrait) {
    width: 100%;
    height: 100%;
    font-size: 1.6em;
    overflow: scroll;
  }

  &.mod-small {
    width: 400*$px;
  }

  &.mod-variable {
    height: inherit;
    min-height: 150*$px;
  }

  &-close{
    position: absolute;
    top: -10*$px;
    right: -10*$px;
    z-index: 10;
    width: 30*$px;

    @media only screen and (orientation: portrait) {
      font-size: 1.8em;
      top: 0;
      right: 0;
    }

    &-img {
      width: 100%;
      height: auto;
    }
  }
}

.popup_info {
  padding: 0*$px;
  z-index: 100;
  padding-bottom: 10*$px;
  padding-right: 10*$px;
  width: 100%;

  &-icon {
    position: absolute;
    top: 5*$px;
    right: 10*$px;
  }

  &-copy {
    display: none;
    font-size: 12*$px;

    &.is-open {
      display: block;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    p {
      padding-bottom: 6*$px;
    }
  }
}

.popup_portal {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;

  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
}

.popup_fullheader {
  &-stat {
    width: 0;
    direction: rtl;
  }
}

.popup_header{
  width: 100%;
  background: #f1f1f1;
  height: 46*$px;
  padding: 0 15*$px 0 15*$px;
  border-radius: 7*$px;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: flex-end;
  z-index: 4;
}

.popup_title {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &-bow {
    background: #f1f1f1;
    position: absolute;
    height: 16*$px;
    left: -15%;
    width: 130%;
    bottom: 100%;
    border-top-left-radius: 50% 16*$px;
    border-top-right-radius: 50% 16*$px;
    border-top: 3*$px solid white;
    border-left: 3*$px solid white;
    border-right: 3*$px solid white;
  }

  &-label {
    display: flex;
    align-items: center;

    .mod-large & {
      font-size: 1.5em;
    }

    .icon {
      margin-right: 4*$px;
      margin-left: 4*$px;
    }
  }
}

.popup_tabs {
  height: 37*$px;
  display: flex;
  align-items: flex-start;

  @media only screen and (orientation: portrait) {
    font-size: 0.9em;
  }
}

.popup_tab{
  position: relative;
  display: block;
  min-width: 82*$px;
  display: flex;
  margin-right: 5*$px;
  height: 37*$px;
  justify-content: center;
  align-items: center;
  padding: 0 18*$px;
  border-top-left-radius: 10*$px;
  border-top-right-radius: 10*$px;
  background: #e2e2e2;
  color: #8c8c8c;

  &.is-active{
    background: white;
    color: #8c8c8c;
  }

  &-icon{
    height: 17*$px;
    margin-right: 9*$px;
  }

  &-label{
    font-size: 12.5*$px;
  }

  &-badges{
    position: absolute;
    top: -0.2em;
    right: -0.2em;
    font-size: 15*$px;
  }
}

.popup_stat {
  position: relative;
  width: fit-content;
  direction: ltr;
  border-radius: 32*$px;
  display: flex;
  align-items: center;
  padding: 2*$px 15*$px;
  margin-bottom: 8*$px;
  margin-right: 4*$px;
  background: linear-gradient(
    to top,
    rgba(256, 256, 256, 0.8),
    rgba(256, 256, 256, 0.8)
  ) currentColor;

  @media only screen and (orientation: portrait) {
    display: none;
  }

  &-amount {
    font-weight: bold;
    font-size: 1em;
  }

  &-icon {
    font-size: 1.3em;
  }

  &.mod-deaths {
    color: #942525;
  }
  &.mod-xp {
    color: $xp;
  }
  &.mod-currency {
    color: $currency;
  }
}

.popup_content {
  position: relative;
  width: 100%;
  flex: 1;
  display: flex;

  @media only screen and (orientation: portrait) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.popup_left {
  position: relative;
  width: 168*$px;
  flex-shrink: 0;

  @media only screen and (orientation: portrait) {
    width: 100%;
  }
}

.popup_right {
  position: relative;
  flex: 1;
  width: 100%;

  &-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
}

.popup_actions {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  >* {
    margin-right: 25*$px;
  }
}

