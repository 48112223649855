@import "style";

.tutorial_ui {
    position: absolute;
    z-index: 9900;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    padding: 15*$px;
    align-items: center;
    justify-content: space-between;
    background: #0116272f;
    justify-content: center;
}

.tutorial_msg {
    position: relative;
    background: white;
    padding: 15*$px;
    border-radius: 10*$px;
    bottom: 20*$px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0.0625em 0.0625em 0.625em black;

    &-close {
        position: absolute;
        top: -10*$px;
        right: -10*$px;
        z-index: 10;
        width: 30*$px;

        @media only screen and (orientation: portrait) {
        font-size: 1.8em;
        top: 0;
        right: 0;
        }

        &-img {
        width: 100%;
        height: auto;
        }
    }

    &-text {
        font-size: 0.9em;
        padding: 5*$px;
    }

    &-confirm {
        flex: 1;
        margin-top: 15*$px;

        a {
            white-space: nowrap;
        }
    }
}

.tutorial_hint {
    position: absolute;
    right: -12*$px;
    bottom: -10*$px;
    z-index: 9999;
    animation: bounce 2s infinite;
    font-size: 2.8rem;

    background: #ffffff7a;
    color: $dark;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}


@keyframes bounce {
0%, 20%, 50%, 80%, 100% {
    transform: translate(0, 0) rotate(-30deg);
}
50% {
    transform: translate(1*$px, 1*$px) rotate(-30deg);
}
}