@import "style";

.cast {
  padding: 0;
  display: flex;
}

.cast_visual {
  position: relative;
  height: 1em;
  width: 1em;
}

.cast_roundvisual {
  position: relative;
  height: 1em;
  width: 1em;
  border-right: 0.08em solid white;
  display: flex;
  align-items: center;
  justify-content: center;

  &-number {
    color: white;
    font-size: 0.62em;
    font-weight: 900;
    text-align: center;
  }
}

.cast_text {
  flex: 1;
  padding: 5*$px 10*$px;
  position: relative;
  font-size: 0.3em;
}

.cast_impression {
  display: inline;
  font-size: 0.8em;
  line-height: 1em/0.8;
  padding-bottom: 0.5em;
}

.cast_effect {
  display: inline;
  font-size: 0.6em;
  line-height: 1em/0.6;
  padding-left: 0.5em;
}
