@import "style";

$headerHeight:48*$px;

.header {
  width: 100%;
  position: fixed;
  left: 0;
  height: $headerHeight;
  z-index: 1;
  top: 0;
  display: flex;
  justify-content: space-between;
  transition: all 0.15s ease-out;
  transform: translateY(0);
  opacity: 1.0;
  pointer-events: none;
  padding: 0 7*$px;

  @media only screen and (orientation: portrait) {
    font-size: 2.2em;
  }

  &.mod-home {
    transform: translateY(-10*$px);
    opacity: 0;
  }
}

.header_group {
  display: flex;
  align-items: center;
  margin: 5*$px;
  pointer-events: initial;
}

.header_hamburger{
  height: $headerHeight;
  width: $headerHeight;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1f1f1;

  &-icon{
    height: 20*$px;
  }
}

.header_button {
  display: flex;
  align-items: center;
  margin-left: 5*$px;

  &-icon{
    height: $headerHeight*1.1;
    width: $headerHeight*1.1;
    margin-right: 5*$px;
  }
  &-label{
    display: none;
    font-size: 10*$px;
  }
}

.header_stat{
  @include elevation;
  position: relative;
  border-radius: 32*$px;
  display: flex;
  align-items: center;
  padding: 8*$px 15*$px;
  background: white;
  color: $dark;

  * + & {
    margin-left: 15*$px;
  }

  &.mod-coin{
    color: $orangeCoin;
  }
  &.mod-time{
    color: $dark;
  }

  &-icon{
    display: block;
    top: 0*$px;
    padding-left: 5*$px;
    width: 22*$px;
    height: 22*$px;
  }

  &-value{
    display: block;
    font-size: 18*$px;
    font-weight: bold;
  }
}

.header_home {
  width: 45*$px;
  height:45*$px;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    font-size: 35*$px;
  }
}