$mobile: #{"only screen and (min-width: 480px)"};
$port: #{"only screen and (min-width: 768px)"};
$land: #{"only screen and (min-width: 1024px)"};
$desktop: #{"only screen and (min-width: 1200px)"};

$px: 1em * (1/16);
$rpx: 1rem * (1/16);

$dark: #011627;
$red: #efaaad;
$green: #cded99;
$light: #F6F7F8;
$blue: #97fae8;

$safe-left: env(safe-area-inset-left);
$safe-right: env(safe-area-inset-left);
$safe-bottom: env(safe-area-inset-bottom);
$safe-top: env(safe-area-inset-top);

$orangeCoin: #eea835;

$gray: #3a3a3a;

$light_brown: #b29b7b;

$currency: #584c03;
$xp: #700ba0;
$health: #ff6165;
$energy: #c1ab00;
$strength: #5288a4;
$intelligence: #FC7405;
$agility: #05af00;
$armor: $strength;
$shielding: $agility;
$resistance: $intelligence;
$time: #7c4613;
$damage: #ff0000;

@mixin elevation {
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}
@mixin elevation2 {
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}
@mixin rounded {
    border-radius: 4*$px;
}

