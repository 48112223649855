@import "style";

$px: (1/150) * 1em;
$avatarWidth: 150 * $px;
$height: 512/372 * $avatarWidth;

.characterslot{
  @include elevation;
  width: 1em;
  height: 1em;
  border: 6 * $px solid white;
  border-radius: 50%;
  position: relative;
  background: $dark;

  &.mod-inactive {
    img {
      filter: grayscale(75%) brightness(60%);
    }
  }
  &.mod-faded{
    border: 6 * $px solid rgba(255, 255, 255, 0.05);
    img {
      filter: grayscale(60%) brightness(70%);
    }
  }

  &-foreground {
    position: absolute;
    pointer-events: none;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &-background {
    border-radius: 50%;
    overflow: hidden;
    background: $dark;
    //box-shadow: 1px 1px 6px black;
  }

  &-img{
    position: absolute;
    height: $height;
    width: 100%;

    bottom: -65/512 * $height;
    left: -65/512 * $height;
    max-width: inherit;
    max-height: inherit;
  }
}
