@import "style";

.campaignui {
    &-map {
        height: 100vh;
        overflow-x: scroll;
        overflow-y: hidden;
    }

    &-ui {
        position: fixed;
        pointer-events: none;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;

        @media only screen and (orientation: portrait) {
            font-size: 3vh;
        }

        &-units {
            pointer-events: all;
            position: absolute;
            left: 0;
            bottom: 30*$px;
        }

        &-market {
            pointer-events: all;
            position: absolute;
            right: 0;
            bottom: 30*$px;
        }
    }
    
    &-children {
        ion-router-outlet {
            pointer-events: none;
        }
    }
}
