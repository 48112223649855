@import "style";

.level {
    width: 100%;
    padding: 15*$px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-prev {
      position: absolute;
      top: 125*$px;
      left: 5*$px;
    }

    &-next {
      position: absolute;
      top: 125*$px;
      right: 5*$px;
    }
  
    &-lineup {
      font-size: 0.8em;
    }
  
    &-actions{
      margin-top: 25*$px;
    }
  
    &-stars {
      position: absolute;
      left: 50%;
      top: 110%;
      font-size: 90*$px;
      transform: translate(-50%, -50%);
    }
}
  
.level_error {
    height: 100%;
    overflow-y: scroll;
}

.level_finished {
    width: 300*$px;
    padding: 0 20*$px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  
      &-label {
        font-size: 13*$px;
        font-weight: bold;
      }
      &-value {
        font-size: 17*$px;
      }
      &-items {
        font-size: 15*$px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
}