@import "style";

.chapters {
    width: 100%;
    height: 100vh;
    color: white;

    &-items {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    &-difficulties {
        position: fixed;
        bottom: 0;
        width: 270*$px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.chapters_item {
    flex: 1;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-size: cover;

    &-title {
        text-align: center;
        font-size: 25*$px;
        width: 110*$px;
        text-shadow: -1px -1px 6px black;
        color: white;
    }

    &-score {
        position: absolute;
        top: -60*$px;
        background: $dark;
        color: white;
        padding: 5*$px 10*$px;
        border-radius: 15*$px;
        display: flex;
        align-items: center;

        .icon {
            margin-right: 5*$px;
        }
    }

    &-state {
        margin-top: 10*$px;

        .button + .button {
            margin-top: 10*$px;
        }
    }
}

.chapters_difficulties {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15*$px 20*$px;
    background: $dark;
    border-top-left-radius: 20*$px;
    border-top-right-radius: 20*$px;

    &-item {
        font-size: 1.2em;
        width: 1.8em;
        height: 1.8em;
        opacity: 0.35;
        border: 2px solid transparent;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        &.is-active {
            opacity: 1;
            border-color: white;
        }
    }
}