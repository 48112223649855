@import "style";

.speeddial {
    @include elevation;
    font-size: 1.6em;
    height: 30*$px;
    background: white;
    border-top-right-radius: 15*$px;
    border-bottom-right-radius: 15*$px;
    padding-right: 35*$px;
    padding-left: $safe-left;

    &:not(.mod-mirrored) {
        padding-left: $safe-left;
    }

    &.mod-mirrored {
        transform: scale(-1,1);
        padding-left: $safe-right;
    }

    &-button {
        position: absolute;
        right: 3*$px;
        bottom: 3*$px;
        width: 24*$px;
        height: 24*$px;
        border-radius: 12*$px;
        background: currentColor;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        >.icon {
            color: white;
        }

        &-badges {
            position: absolute;
            top: -0.2em;
            right: -0.2em;
            font-size: 10*$px;
        
            .mod-mirrored & {
                top: -0.35em;
                right: inherit;
                left: -0.2em;
            }
        }
    }

    &-children {
        position: absolute;
        bottom: 30*$px;
        right: 3*$px;
        left: 10*$px;
        display: flex;
        flex-direction: column-reverse;   
        align-items: flex-end;
    }
}

.speeddial_action {
    transform: scale(0);
    font-size: 24*$px;
    transition: 0.13s ease-out;

    &.mod-round {
        margin-bottom: 4*$px;
        font-size: 16*$px;
        padding: 5*$px;
        background: $dark;
        color: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @for $i from 1 to 10 {
        &:nth-last-child(#{$i}) { transition-delay: $i * 0.03s; }
    }

    &.is-open {
        transform: scale(1);
        @for $i from 1 to 10 {
            &:nth-child(#{$i}) { transition-delay: $i * 0.03s; }
        }
    }

    &-badges {
        position: absolute;
        top: -0.35em;
        right: -0.35em;
        font-size: 7*$px;
        
        .mod-mirrored & {
            top: -0.35em;
            left: 0.3em;
        }
    }
}