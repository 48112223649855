@import "style";

.ads_popup {
    position: relative;
    width: 100%;
    padding: 20*$px;
    
    hr {
      margin-top: 8*$px;
    }

    &-buttons {
      display: flex;
      .button {
        margin-right: 15*$px;
      }
    }

    &-message {
        padding: 12*$px 0;
        font-size: 0.8em;
    }
}

.ads_view {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;

  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
}