@import "style";


.talentstab_group {
  background: $dark;
  color: white;
  border-radius: 4*$px;
  padding: 4*$px 7*$px 5*$px 7*$px;

  & + & {
    margin-top: 7*$px;
  }

  &-items {
    display: flex;
    justify-content: space-between;
  }

  &-talents {
    position: relative;

    &-title {
      padding: 4*$px 9*$px;
      font-size: 10*$px;
    }

    &-items {
      display: flex;
      flex-wrap: wrap;
      border-radius: 4*$px;
      position: relative;

      .mod-expert & {
        background: gold;
      }
    }
  }
}

.talentstab_item {
  font-size: 2.65em;
  height: 1em;
  margin: 2*$px;
  position: relative;

  &.mod-small {
    font-size: 2.2em;
  }
}
