@import "style";

.product {
    position: relative;
    flex-shrink: 0;
    width: 108*$px;
    min-height: 220*$px;
    background: $dark;
    color: white;
    padding: 15*$px 8*$px;
    border-radius: 5*$px;
    display: flex;
    flex-direction: column;

    &-info {
        flex: 1;
    }
    &-actions {
        font-size: 0.8em;
    }

    &-label {
        text-align: center;
        font-size: 12*$px;
        min-height: 45*$px;
    }

    &-amount {
        font-size: 0.8em;
        margin-bottom: 10*$px;
    }

    &-top {
        display: flex;
        justify-content: center;

        &-image {
            display: flex;
            justify-content: center;
            font-size: 2.5em;
            margin-bottom: 6*$px;
        }

        &-stats {
            padding-left: 1em;
            font-size: 0.7em;
        }
    }


    &-stats {
        border-top: 1px solid white;
        font-size: 0.7em;
        padding-top: 10*$px;
        margin-bottom: 5*$px;
    }
}