@import "style";

$px: 0.03em;

.card {
  position: relative;
  display: flex;
  backface-visibility: hidden;
  transition: 0.25s ease-out;
  opacity: 1.0;

  @media only screen and (orientation: portrait) {
    font-size: 1.6em;
    flex-direction: column !important;
  }

  &.is-reversed {
    flex-direction: row-reverse;
  }
  &.is-active {
    .card-highlight {
      opacity: 1.0;
    }
  }

  &-highlight {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    transition: opacity 0.25s ease-out;
    opacity: 0.0;
    border-radius: 50%;
    box-shadow: 0 0 75*$px 75*$px;
    color: white;
  }

  &-avatar {
    z-index: 2;
    transform: scale(-1, 1);
    font-size: 100*$px;

    .is-reversed & {
      transform: scale(1, 1);
    }

    &-ability {
      position: relative;
      font-size: 0.4em;
      margin-top: -27 * $px;
      margin-left: -10 * $px;
      .visual {
        border: 1px solid white;
        box-sizing: initial;
      }
      .is-dead & {
        opacity: 0.0;
      }
      .is-stunned & {
        opacity: 0.0;
      }
    }

    &-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 0.7em;
      width: 1em;
      height: 1em;
      margin-left: -0.5em;
      margin-top: -0.5em;
      color: white;
    }

    &-effects {
      z-index: 20;
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 0.65em;
      transform: scale(-1, 1);

      .is-reversed & {
        transform: scale(1, 1);
      }
    }
  }

  &-stats{
    display: flex;
    flex-direction: column;
    padding-top: 10 * $px;
    margin-left: -43 * $px;
    align-items: flex-start;

    @media only screen and (orientation: portrait) {
      align-items: start !important;
      margin: 0 !important;
    }

    .is-reversed & {
      margin-right: -43 * $px;
      align-items: flex-end;
    }

    &-hp {
      position: relative;
      z-index: 0;
      color: #fd7c86;
    }

    &-energy {
      position: relative;
      z-index: 1;
      margin-top: -2 * $px;
      color: #f9ef4a;
    }

    &-name {
      color: white;
      margin-left: 140 * $px;
      font-size: 15 * $px;
      margin-top: 20 * $px;

      .is-reversed & {
        margin-left: inherit;
        margin-right: 140 * $px;
      }

      @media only screen and (orientation: portrait) {
        display: none;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
  }

  &-modifiers {
    font-size: 0.85em;
    position: relative;
    padding-left: 45*$px;
    height: 1em;

    .is-reversed & {
      padding-right: 45*$px;
      padding-left: 0;
    }

    &-icons {
      max-width: 160*$px;
      display: flex;
      align-items: flex-end;
    }
  }
}

.card_modifier {
  width: 1em;
  height: 1em;
  left: 0;
  position: relative;

  &-container {
    top: 0;
    position: absolute;
    width: 1em;

    .is-reversed & {
      right: 0;
      left: inherit;
    }
  }
}

.card_ability {
  position: absolute;
  top: 0;
  left: 0;

  &.mod-end {
    transform: scale(0.6) translate(20*$px, 20*$px);

    .card_ability-arrow {
      opacity: 1.0;
    }
  }

  &-arrow {
    position: absolute;
    top: 3.5*$px;
    left: -21*$px;
    font-size: 1em;
    opacity: 0.0;
    transition: 0.25s ease-out;
    color: white;
  }
}

.card_effect {
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  display: flex;
  align-items: center;
  padding: 3*$px;
  border-radius: 8*$px;
  font-weight: bold;
  text-shadow: 0px 0px 1px black;
  transform-origin: 0 0;
  animation: pop_effect;
  transform: scale(0) translate(-50%, -50%);

  &-icon{
    font-size: 0.8em;
    margin-left: 3*$px;
  }

  &.mod-health {
    color: $health;
  }
  &.mod-energy {
    color: $energy;
  }
  &.mod-strength {
    color: $strength;
  }
  &.mod-agility {
    color: $agility;
  }
  &.mod-armor {
    color: $armor;
  }
  &.mod-intelligence {
    color: $intelligence;
  }
  &.mod-resistance {
    color: $resistance;
  }
}

/* Standard syntax */
@keyframes pop_effect {
  0%   {
    transform: scale(1.0) translate(-50%, -50%);
    opacity: 0;
  }
  5%  {
    transform: scale(2.3) translate(-50%, -50%);
    opacity: 1;
  }
  95%  {
    transform: scale(2.3) translate(-50%, -50%);
    opacity: 1;
  }
  100% {
    transform: scale(1.0) translate(-50%, -50%);
    opacity: 0;
  }
}
