@import 'style';

.home {
    color: white;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 13*$px;
    position: relative;

    &-discord {
        position: absolute;
        font-size: 20*$px;
        bottom: 8*$px;
        right: 8*$px;
        margin-left: $safe-left;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #01162775;
        width: 1.8em;
        height: 1.8em;
        border-radius: 50%;
    }

    &-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #f8f8ff;

        &-main {
            font-weight: 900;
            font-size: 55*$px;
            text-transform: uppercase;
            margin: 0;
            position: relative;
        }

        &-sub {
            font-weight: bold;
            height: 30*$px;
            width: fit-content;
            padding: 0 10*$px;
            font-size: white;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #452713;
            border-radius: 20*$px; 
        }
    }

    &-menu {
        padding-top: 20*$px;
        font-size: 0.85em;
    }

    &-feedback {
        position: absolute;
        top: 10px;
        right: 10px;
        text-align: center;

        a {
            text-decoration: underline;
        }
    }
}

.home_links {
    position: absolute;
    bottom: 20*$px;
    right: 15*$px;
    font-size: 12*$px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    a {
        text-decoration: underline;
        color: $dark;
        font-weight: bold;
    }

    a + a {
        margin-top: 20*$px;
    }
}

.home_message {
    position: absolute;
    top: 15*$px;
    right: 15*$px;
    padding: 15*$px;
    text-align: center;
    background: #011627;
    color: white;
    max-width: 300*$px;

    &-button{
        padding-top: 15*$px;
        display: flex;
        justify-content: center;
    }
}

.home_btn {
    display: inline-flex;
    width: 100*$px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    
    &-img {
        width: 70%;
        height: auto;
    }

    &-label {
        font-weight: bold;
        text-decoration: none;
        font-size: 0.85em;
    }
}