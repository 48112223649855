@import "style";

.requirements{
  margin-top: 8*$px;
  border-top: 1px solid white;
  padding-top: 5*$px;
  font-size: 0.7em;

  &-list {
    font-size: 0.8em;

    &-item {
      margin-top: 3*$px;
      padding-right: 20*$px;
    }
  }
}

.requirements_item {
  display: flex;

  &.is-valid {
    opacity: 0.5;
  }

  &-symbol {
    flex-grow: 0;
    margin-right: 5*$px;
  }

  &-description {
    flex: 1;
  }

  &-valid {
    flex-grow: 0;
    padding-left: 8*$px;
  }
}
