@import 'style';

.label {
    width: fit-content;
    padding: 3*$px 10*$px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20*$px; 
    color: white;
    background: $dark;
}