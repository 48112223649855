@import "style";

.characterinfo {
  position: relative;
  width: 168*$px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20*$px 0 0 0;
  z-index: 10;

  @media only screen and (orientation: portrait) {
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
  }

  &-top {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    width: 100%;

    @media only screen and (orientation: portrait) {
      flex: 0.4;
      width: inherit;
    }
  }

  &-prev{
    position: absolute;
    top: 75*$px;
    left: 10*$px;
  }

  &-next{
    position: absolute;
    top: 75*$px;
    right: 10*$px;
  }

  &-avatar {
    font-size: 72*$px;
    position: relative;
  }

  &-info {
    margin-top: 10*$px;
    text-align: center;

    &-name{
      color: #3f3c38;
      font-size: 13*$px;
      font-weight: bold;
      margin-bottom: 3*$px;
    }
    &-level{
      color: #27dc95;
      font-size: 11*$px;
    }
  }

  &-stats{
    width: 100%;
    padding: 0 10*$px 10*$px 10*$px;
    font-size: 10*$px;
    font-weight: bold;
    color: #a59f96;

    @media only screen and (orientation: portrait) {
      flex: 0.5;
    }

    &-spacer {
      width: 100%;
      height: 1px;
      background: #a59f96;
      margin-top: 4*$px;
      margin-bottom: 4*$px;
    }
  }
}

.characterinfo_stat{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.45em;

  & + & {
    margin-top: 4*$px;
  }

  &-label{
    margin-left: 10*$px;
    width: 100*$px;
    text-align: left;
    font-size: 0.9em;
  }

  &-value{
    margin-left: 30*$px;
    width: 35*$px;
    text-align: right;
    font-size: 0.9em;
  }
}
