@import "style";

.button {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3*$px;
  padding: 8*$px 12*$px;
  background: #58c058;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15*$px;

  &.mod-negative {
    background: #98141a;
  }
  &.mod-xp {
    color: $xp;
  }
  &.mod-currency {
    color: $currency;
  }
  &.mod-xp, &.mod-currency {
    background: linear-gradient(
      to top,
      rgba(256, 256, 256, 0.82),
      rgba(256, 256, 256, 0.82)
    ) currentColor;
  }

  &.is-disabled {
    opacity: 0.5;
  }

  >*:not(:first-child){
    margin-left: 15*$px;
  }
}

.button_price {
  display: flex;
  align-items: center;
  .staticon {
    font-size: 1.5em;
  }
}

.button_icon {
  margin-left: 5*$px;
  display: flex;
  align-items: center;
  .icon {
    font-size: 1.3em;
  }
}