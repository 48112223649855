@import "style";

$w: 160 * $px;
$h: 60 * $px;

.snapshot {
  position: relative;
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .bg {
    background-position: center top;
  }

  &-tiles{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-chars{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-tiles, &-chars {
    width: 4*$w;
    height: 4*$h;

    @media only screen and (orientation: portrait) {
      height: 4*$w;
    }
  }
}

.snapshot_message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 20*$px;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity fade-in 0.3s 0.2s;

  &.is-visible {
    opacity: 1;
    pointer-events: all;
  }

  &-message {
    color: white;
    font-weight: bold;
    font-size: 60*$px;
    margin-bottom: 5*$px;
    text-shadow: 2px 2px 15px $dark;
  }

  &-button {
    text-align: center;
  }
}

.snapshot_tile {
  position: absolute;
  top: 0;
  left: 0;
  width: 1*$w;
  height: 1*$h;
  transform: translate(0,0);

  @media only screen and (orientation: portrait) {
    height: 1*$w;
  }

  &.mod-odd{
    background: rgba(0, 0, 0, 0.15);
  }
  &.mod-even{
    background: rgba(255, 255, 255, 0.15);
  }
}

.snapshot_char {
  position: absolute;
  top: 0;
  left: 0;
  width: 1*$w;
  height: 1*$h;
  transform: translate(0,0);
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (orientation: portrait) {
    height: 1*$w;
  }
}
