.gamemap {
    display: block;
    position: relative;
    height: 100vh;
    width: fit-content;

    &-img {
        height: 100%;
        width: auto;
        max-width: inherit;
    }

    &-levels {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        @for $i from 1 through 30 {
            .level:nth-child(#{$i}){
                animation: gamemap-fadein ease #{$i * 0.12 + 0.3}s;
                animation-timing-function: ease-in;
            }
        }
        
    }
}

@keyframes gamemap-fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}