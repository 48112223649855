@import "style";

.replay {
  background: $dark;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;

  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: cover;
    background-position: center top;
  }

  &.is-logslocked {
    padding-left: 235 * $px;
    .replay-board{
      font-size: 0.6em;
    }
  }

  &-snapshot {
    margin-top: 48 * $px;
  }

  &-content {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}
