@import "style";

.controls {
  position: relative;
  background: white;
  color: $dark;
  padding-left: $safe-left;
  padding-right: $safe-right;
  padding-bottom: 2*$px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media only screen and (orientation: portrait) {
    font-size: 1.5em;
  }
}

.controls_group {
  display: flex;
}

.controls_button {
  display: flex;
  min-width: 60*$px;
  height: 50*$px;
  padding: 0 10*$px;
  justify-content: center;
  align-items: center;
  opacity: 0.5;

  &-text {
    font-size: 14*$px;

    @media only screen and (orientation: portrait) {
      display: none;
    }
  }

  svg {
    margin-right: 3*$px;
  }

  &.is-active {
    opacity: 1.0;
  }

  .icon {
    font-size: 26*$px;
  }
}

.controls_slider {
  width: 100%;
  max-width: 200*$px;
  margin: 8*$px auto 8*$px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 25*$px;

  &-input {
    appearance: none;
    width: 100%;
    height: 10 * $px;
    border-radius: 5 * $px;
    background: $dark;
    outline: none;

    &::-webkit-slider-thumb {
      width: 22 * $px;
      height: 22 * $px;
      border-radius: 50%;
      background: $dark;
      cursor: pointer;
      appearance: none;
    }
    &::-moz-range-thumb {
      width: 22 * $px;
      height: 22 * $px;
      border-radius: 50%;
      background: $dark;
      cursor: pointer;
    }
  }
}
