@import "style";

$gut: 6*$px;

.equipslot {
  background: $dark;
  color: $light;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8*$px;
  position: relative;

  &.is-locked {
    opacity: 0.2;
  }

  &.is-active {
    border: 3*$px solid #6bd043;
  }

  &-icon {
    opacity: 0.65;
    flex-grow: 0;
    font-size: 38*$px;
    height: 1em;
    padding: 5*$px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.25em;
  }

  &-label {
    opacity: 0.65;
    flex: 1;
    font-size: 8*$px;
    font-weight: lighter;
    text-align: center;
  }
}

.equipslot_item {
  font-size: 0.7em;

  &-header {
    display: flex;
    justify-content: center;
    align-items: center;

    &-visual {
      font-size: 3em;
    }

    &-extra {
      margin-left: 8*$px;
      font-size: 0.9em;
    }
  }

  &-stats {
    margin-top: 5*$px;
    padding-top: 5*$px;
    border-top: 1px solid $light;
    font-size: 0.8em;
  }
}
